.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* This will change the scroll bar globally */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f100; 
  border-radius: 99px;
}

::-webkit-scrollbar-thumb {
  background: #c7ccd5; 
  border-radius: 99px;
}

::-webkit-scrollbar-thumb:hover {
  background: #bbbbbb; 
}

.ltr {
  direction: ltr;
}

.spinner {
  border: 4px solid rgba(203, 189, 239, 0.508);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border-left-color: rgb(188, 211, 226);
  animation: spin 1s linear infinite;
}

.popup {
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.popup::before {
  content: "";
  position: absolute;
  bottom: 20%;
  inset-inline-start: 0px;
  width: 0;
  height: 0;
  border-inline-end: 10px solid white;
  border-block-start: 10px solid transparent;
  border-block-end: 10px solid transparent;
}

.popup.ltr::before {
  transform: translateX(-100%);
}

.popup.rtl::before {
  transform: translateX(100%);
}

.popup.active {
  opacity: 1;
}

.fix-and-merge::before {
  content: "";
  position: absolute;
  bottom: -10px;
  /* inset-inline-start: 50%; */
  width: 0;
  height: 0;
  border-block-start: 10px solid white;
  border-inline-start: 10px solid transparent;
  border-inline-end: 10px solid transparent;
}

.fix-and-merge.ltr::before {
  transform: translateX(1rem);
}

.fix-and-merge.rtl::before {
  transform: translateX(-1rem);
}

.loading img {
  animation: fadeInOut 2s infinite; /* you can adjust the 2s to however long you want the transition to last */
}

.truncate-2-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* rtl email editor styles */
/* [dir="rtl"] [style="margin-left: -100%;"]{
  margin-right: -100% !important;
}

[dir="rtl"] [style="margin-left: -200%;"]{
  margin-right: -200% !important;
}

[dir="rtl"] [id="0_children_1_attributes_background-position"],
[dir="rtl"] .arco-input-inner-wrapper-default,
[dir="rtl"] .easy-email-pro-editor-controller{
  direction: ltr;
}

[dir="rtl"] [style="position: absolute; right: 0px;"]{
  right: auto !important;
  left: 16px !important;
}

[dir="rtl"] [style="position: absolute; left: 0px;"]{
  left: auto !important;
  right: 16px !important;
}

[dir="rtl"] .HoveringToolbarWrapper{
  direction: ltr;
}

.block-list-grid-item{
  border-radius: 1rem !important;
}

[dir="rtl"] .arco-btn.arco-btn-text.arco-btn-size-large.arco-btn-shape-square.arco-btn-icon-only {
  transform: rotate(180deg);
} */
/*  */

@keyframes fadeInOut {
  0% {opacity: 0.7;}
  50% {opacity: 1;}
  100% {opacity: 0.7;}
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
